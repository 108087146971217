import React from 'react';
import { Link } from 'gatsby';
import blogStyles from '../../styles/_components/_news_hub/_news_hub_item.module.scss';
import { TextGradient } from '../../components/_text/_text_gradient';
import { ButtonSemicircle, Button_Semicircle_types } from '../../components/buttons/_button_semicircle';
import { Picture } from 'react-responsive-picture';

export const NewsHubItemTypes = {
  MAIN: 'oNewsHubItem__main',
  TO_LEFT: 'oNewsHubItem__left',
  TO_RIGHT: 'oNewsHubItem__right',
  SINGLE: 'oNewsHubItem__single',
}

export const NewsHubItem = ({ blog, type, single_layout = '', _className, _related = false, _label = 'Leer Nota' }) => {
  let short_description = blog.node.data.content.text !== null ? blog.node.data.content.text.length > 120 ? blog.node.data.content.text.substring(0, 120) + ' ...' : blog.node.data.content.text : " ";
  let single_layout_class = type === NewsHubItemTypes.SINGLE ? blogStyles[`oNewsHubItem__single${single_layout}`] : '';

  return (
    <div className={`${_className} ${_className + '-' + single_layout} ${blogStyles.oNewsHubItem} ${blogStyles[type || NewsHubItemTypes.SINGLE]} ${single_layout_class}`}>
      <div className={blogStyles.oNewsHubItemSectionHeader}>
        <div className={blogStyles.oNewsHubItemSectionHeader__bg}></div>
        <p className={blogStyles.oNewsHubItemSectionHeader__text}>Calorex</p>
      </div>
      <div className={`${blogStyles.oNewsHubItemStage} o-news-hub-item_stage`}>
        <div className={blogStyles.oNewsHubItemImg__container} >
          <Link to={`/calorex-news-hub/${blog.node.uid}`}>
            <Picture
              sources={[
                {
                  srcSet: blog.node.data.main_image.url,
                  media: "",
                }
              ]}
            />
          </Link>
        </div>
        <div className={`o-news-hub-item_info ${blogStyles.oNewsHubItemInfo__container}`}>
          <div className="o-news-hub-item_text">
            <TextGradient _text={blog.node.data.title.text} _className={blogStyles.oNewsHubItem__title}></TextGradient>
            <p className={`o-news-hub-item_content ${blogStyles.oNewsHubItemContent}`}>{short_description}</p>
          </div>
          <div className={`o-news-hub-item_button_content ${blogStyles.oNewsHubItem__button_container}`}>
            <ButtonSemicircle
              _slug={blog.node.uid}
              _text={_label}
              _to={`/calorex-news-hub/${blog.node.uid}`}
              _className={`news-hub_more_button_item`}
              _button_type={Button_Semicircle_types.LIGHT}
            >
            </ButtonSemicircle>
          </div>
        </div>
      </div>
    </div>
  );
}
