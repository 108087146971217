import React from 'react'
import { Link } from 'gatsby'
import '../../components/buttons/_button_semicircle';

const NewsHubMenu = (props) => {
  return (
    <Link className="news-hub_header" to='/calorex-news-hub'>
      <h2 className="news-hub_header_title">Calorex News Hub</h2>
    </Link>
  )
}

export default NewsHubMenu;
