import React from "react"
import Layout from '../components/layout';

import { TextGradient } from '../components/_text/_text_gradient';
import { ButtonArrow } from '../components/buttons/_btn_arrow';

import NewsHubMenu from "../content/news-hub/news_hub_menu"

import { usePrismNewsHub } from "../hooks/use_prism-news-hub"
import { NewsHubSearch } from '../components/_news_hub/_news_hub_search';
import { NewsHubItem, NewsHubItemTypes } from "../components/_news_hub/_news_hub_item";
import { Picture } from 'react-responsive-picture';
import GetBodyItem from '../components/_helpers/_get_body_item'

import "../styles/pages/news-hub/news_hub.scss"

const NewsHubDetail = (props) => {
  let _item = props.pageContext.item;
  const blogs = usePrismNewsHub().edges;
  const total = blogs.length;
  const index = blogs.findIndex((blog) => blog.node.uid === _item.node.uid);
  const prevPost = total > 2 ? index === 0 ? blogs[total - 1] : blogs[index - 1] : null;
  const nextPost = total > 1 ? index === total - 1 ? blogs[0] : blogs[index + 1] : null;

  console.log('prevPost', prevPost);
  console.log('nextPost', nextPost);

  let metas_from_prismic = new GetBodyItem().get_item(_item.node, "PrismicCalorexNewsHubBodyMetas");
  let current_blog_uid = _item.node.uid;
  let blogs_clean = [];
  for (let i = 0; i < total; i++) {
    if (blogs[i].node.uid !== current_blog_uid) {
      blogs_clean.push(blogs[i]);
    }
  }
  let metas = {
    title: 'Calorex News Hub',
    description: 'Calorex News Hub Descripción',
    image: ''
  }
  if (metas_from_prismic) {
    metas.title = metas_from_prismic.primary.title.text
    metas.description = metas_from_prismic.primary.description.text
    metas.image = metas_from_prismic.primary.image_share.url
  }

  function set_date_format(_date) {
    let date_array = _date.split('-');
    const months = ['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    return date_array[2] + ' de ' + months[parseInt(date_array[1])] + ' ' + date_array[0];
  }
  function get_detail(_item) {
    return (
      <div className="news-hub_detail_container">
        <p className="news-hub_detail_date">{set_date_format(_item.node.data.date)}</p>
        <div className="news-hub_detail_content_top news-hub_detail_content" dangerouslySetInnerHTML={{ __html: _item.node.data.content.html }}></div>
        {_item.node.data.body !== null ?
          _item.node.data.body.map((_block, index) => {
            switch (_block.__typename) {
              case "PrismicCalorexNewsHubBodyCopy":
                return (
                  <TextGradient
                    key={index}
                    _text={_block.primary.title1.text}
                    _color_start='#fc6366'
                    _color_end='#f43b59'
                    _deg={180}
                    _className={'news-hub_detail_content_copy'} />
                )
              case "PrismicCalorexNewsHubBodyTexto":
                return (
                  <div key={index} className="news-hub_detail_content_bottom news-hub_detail_content" dangerouslySetInnerHTML={{ __html: _block.items[0].description.html }}></div>
                )

              default:
                return (null);
            }
          })
          : null
        }
      </div>
    );

  }

  return (
    <Layout>
      <NewsHubSearch></NewsHubSearch>
      <div className="news-hub-container news-hub-container--on-detail">
        <div className="news-hub_header_stage">
          <div className="news-hub_carrousel">
            <TextGradient
              _text={_item.node.data.title.text}
              _color_start='#d70b2d'
              _color_end='#bc0c2f'
              _deg={180}
              _className={'news-hub_carrousel_item__title'} />
            <Picture
              sources={[
                {
                  srcSet: _item.node.data.main_image.url,
                  media: "",
                }
              ]}
            />
          </div>
          <NewsHubMenu path={`/calorex-news-hub`}></NewsHubMenu>
          <ButtonArrow _className='news-hub_detail_item__return' _text='Regresar' _to={"/calorex-news-hub"} />
        </div>
        <div className="news-hub_body">
          <div className='news-hub_body_main'>
            <div className="news-hub_detail_share">
            </div>
            {get_detail(_item)}
            <div className='news-hub_prev_next_container'>
              <div className='news-hub_prev_container'>
                {prevPost && prevPost.node && (
                  <>
                    <NewsHubItem
                      _className='news-hub_body_related_item'
                      _label="Leer Anterior"
                      blog={prevPost}
                      type={NewsHubItemTypes.SINGLE}
                    ></NewsHubItem>
                  </>
                )}
              </div>
              <div className='news-hub_next_container'>
                {nextPost && nextPost.node && (
                  <>
                    <NewsHubItem
                      _className='news-hub_body_related_item'
                      _label="Leer Siguiente"
                      blog={nextPost}
                      type={NewsHubItemTypes.SINGLE}
                    ></NewsHubItem>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className='news-hub_body_related'>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NewsHubDetail
