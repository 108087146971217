import { useStaticQuery, graphql } from "gatsby"
export const usePrismNewsHub = () => {
  const prismic = useStaticQuery(
    graphql`
      {
        allPrismicCalorexNewsHub {
          edges {
            node {
              uid
              type
              first_publication_date
              data {
                date
                title {
                  text
                }
                content {
                  html
                  text
                }
                body {
                  __typename
                  ... on PrismicCalorexNewsHubBodyMetas {
                    slice_type
                    primary {
                      title {
                        text
                      }
                      description {
                        text
                      }
                      image_share {
                        url
                      }
                    }
                  }
                  __typename
                  ... on PrismicCalorexNewsHubBodyCopy {
                    primary {
                      title1 {
                        html
                        text
                      }
                    }
                  }
                  __typename
                  ... on PrismicCalorexNewsHubBodyTexto {
                    items {
                      description {
                        html
                        text
                      }
                    }
                  }
                }
                main_image {
                  url
                }
              }
            }
          }
        }
      }
    `
  );

  return prismic.allPrismicCalorexNewsHub
}
